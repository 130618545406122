import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { initialsFromName } from "helpers/formatting";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import {
  cancelEventBooking,
  expireEventBooking,
  unexpireEventBooking,
  makeBookableEventBooking,
  deleteEventBooking,
} from "lib/api";
import Badge from "lunar/Badge";
import Button from "lunar/Button";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import DialogActionPanel from "lunar/DialogActionPanel";
import ButtonText from "lunar/ButtonText";

Header.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  booking: PropTypes.object,
};

export default function Header({ booking = null, handleError, handleSuccess }) {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [openCancelReservation, setOpenCancelReservation] = useState(false);
  const [openDeleteReservation, setOpenDeleteReservation] = useState(false);
  const [openPublishReservation, setOpenPublishReservation] = useState(false);
  const [openExpireReservation, setOpenExpireReservation] = useState(false);
  const [openUnexpireReservation, setOpenUnexpireReservation] = useState(false);

  async function handleCancel() {
    const response = await cancelEventBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Booking cancelled");
    }
  }

  async function handleExpire() {
    const response = await expireEventBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Event booking expired");
    }
  }

  async function handleUnexpire() {
    const response = await unexpireEventBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Event booking unexpired");
    }
  }

  async function handlePublish() {
    const response = await makeBookableEventBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      handleSuccess("Event booking is now bookable");
    }
  }

  async function handleDelete() {
    const response = await deleteEventBooking(booking.transaction_number);
    if (response.errorMsg) {
      handleError(response.errorMsg);
    } else {
      addToast("Booking deleted", { appearance: "success" });
      navigate("/event_bookings");
    }
  }

  const dialogDesc = () => {
    const requiredData = [
      {
        label: "number of adults",
        value: booking.num_adults,
      },
      {
        label: "pricing total",
        value: parseFloat(booking.total) > 0 ? booking.total : null,
      },
    ];

    const missingData = requiredData.filter((d) => !d.value);

    if (missingData.length > 0) {
      const missingLabels = missingData.map((d) => d.label);
      const lf = new Intl.ListFormat("en");
      const missingString = lf.format(missingLabels);

      return {
        text: `Please update ${missingString} before making this reservation bookable.`,
        missingData: true,
      };
    }
    return {
      text: "This will make the reservation bookable.  Are you sure you want to do this?",
      missingData: false,
    };
  };

  const reservationUri = (eventType) => {
    switch (eventType) {
      case "residency":
        return "residencies";
      case "training_trip":
        return "surfer-development";
      case "group_trip":
        return "events";
      case "wsl":
        return "events";
    }
  };

  return (
    <div className="flex flex-row items-center gap-10">
      <div className="inline-flex items-center flex-grow gap-4">
        <div className="w-auto">
          <div className="inline-flex items-center justify-center w-12 h-12 bg-gray-500 rounded-full">
            <span className="font-medium leading-none text-white">
              {initialsFromName(`${booking.first_name} ${booking.last_name}`)}
            </span>
          </div>
        </div>
        <div>
          <div className="inline-flex items-center grid-cols-2 gap-3">
            <h4 className="text-2xl font-bold">
              {booking.first_name} {booking.last_name}
            </h4>
            <div className="w-auto">
              <Badge status={booking.status} />
            </div>
          </div>
          <p className="mt-1 text-sm font-semibold text-gray-600">{`Reserving ${booking.event_variant.event.name}`}</p>
        </div>
      </div>
      <div className="text-right">
        <div className="inline-grid items-center grid-flow-col gap-2">
          <a
            href={`https://www.thermal.travel/reservations/${reservationUri(booking.event_variant.event.event_type)}/${booking.transaction_number}`}
            rel="noreferrer"
            target="_blank"
            className="mr-4 text-sm font-medium text-blue-500 hover:underline"
          >
            View as traveler
          </a>

          {booking.status === "created" && (
            <Button onClick={() => setOpenPublishReservation(true)}>
              Make bookable
            </Button>
          )}

          <Menu as="div" className="relative z-10 inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="flex items-center text-gray-900 bg-white rounded-full focus:outline-none">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        <ButtonText
                          onClick={() => setOpenExpireReservation(true)}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                          disabled={
                            booking.status === "created" ||
                            booking.status === "expired"
                          }
                        >
                          Mark as expired
                        </ButtonText>
                      </Menu.Item>
                      <Menu.Item>
                        <ButtonText
                          onClick={() => setOpenUnexpireReservation(true)}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                          disabled={booking.status !== "expired"}
                        >
                          Unexpire
                        </ButtonText>
                      </Menu.Item>
                      <Menu.Item>
                        <ButtonText
                          onClick={() => setOpenCancelReservation(true)}
                          className="block w-full px-4 py-2 text-sm text-left focus:ring-blue-500 hover:bg-blue-50"
                          disabled={booking.status === "created"}
                        >
                          Cancel
                        </ButtonText>
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        <ButtonText
                          onClick={() => setOpenDeleteReservation(true)}
                          className="block w-full px-4 py-2 text-sm text-left text-red-500 focus:ring-blue-500 hover:bg-blue-50"
                          disabled={booking.status !== "created"}
                        >
                          Delete
                        </ButtonText>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      <DialogActionPanel
        title="Make reservation bookable"
        description={dialogDesc().text}
        buttonText={"Make bookable"}
        onClick={handlePublish}
        open={openPublishReservation}
        setOpen={setOpenPublishReservation}
        disabled={dialogDesc().missingData}
      />
      <DialogActionPanel
        title="Expire reservation"
        description="This will expire the reservation.  Are you sure you want to do this?"
        buttonText={"Expire reservation"}
        onClick={handleExpire}
        open={openExpireReservation}
        setOpen={setOpenExpireReservation}
      />
      <DialogActionPanel
        title="Unexpire reservation"
        description="This will unexpire the reservation and reset the status to bookable.  Are you sure you want to do this?"
        buttonText={"Unexpire reservation"}
        onClick={handleUnexpire}
        open={openUnexpireReservation}
        setOpen={setOpenUnexpireReservation}
      />
      <DialogActionPanel
        title="Cancel reservation"
        description="This will cancel the reservation.  Are you sure you want to do this?"
        buttonText={"Cancel reservation"}
        onClick={handleCancel}
        open={openCancelReservation}
        setOpen={setOpenCancelReservation}
      />
      <DialogActionPanel
        title="Delete reservation"
        description="This will delete the reservation from the system.  Are you sure you want to do this?"
        buttonText={"Delete reservation"}
        onClick={handleDelete}
        open={openDeleteReservation}
        setOpen={setOpenDeleteReservation}
      />
    </div>
  );
}
